import { Box } from '@chakra-ui/react';
import { autocomplete } from 'getaddress-autocomplete';
import {
  createRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getGetAddressApiKey } from '../../utils/getEnvData';
import { InputProps } from '../Input';
import { SearchInput } from '../Input/SearchInput';

export interface GetAddressInputProps extends Omit<InputProps, 'onSelect'> {
  label?: string;
  value?: string;
  onSelect: (address: Partial<Addresses.Address>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onClear?: VoidFunction;
  isHideClearButton?: boolean;
  icon?: string;
}

// when selecting the venue group, if the config.country_code == "GB" use the getaddress.io tool to find addresses.

const apiKEY = getGetAddressApiKey();

interface AddressSelectedEvent extends Event {
  address: {
    formatted_address: string[];
    longitude: number;
    latitude: number;
    postcode: string;
    town_or_city: string;
    sub_building_number: string;
  };
}

const GetAddressInputComponent = ({
  label,
  onSelect,
  onFocus,
  onBlur,
  onClick,
  onClear,
  icon,
  value = '',
  isHideClearButton,
  ...restInputProps
}: GetAddressInputProps) => {
  const [text, setText] = useState<string>(value);
  const inputRef = createRef<HTMLInputElement>();
  const autocompleteRef = useRef<ReturnType<typeof autocomplete> | null>(null);

  const handleAddressSelected = useCallback(
    (event: Event) => {
      const e = event as AddressSelectedEvent;

      const {
        longitude,
        latitude,
        postcode,
        town_or_city,
        sub_building_number,
      } = e.address;
      const selectedAddress = e.address.formatted_address
        .filter((part) => part !== '')
        .join(', ');

      setText(selectedAddress);

      onSelect({
        formatted_address: selectedAddress,
        full_address: selectedAddress,
        lat: latitude,
        lng: longitude,
        eircode: postcode,
        city: town_or_city,
        flat_no: sub_building_number,
      });
    },
    [onSelect],
  );

  useEffect(() => {
    if (inputRef.current && !autocompleteRef.current) {
      autocompleteRef.current = autocomplete(inputRef.current.id, apiKEY, {
        suggestion_count: 5,
      });

      if (autocompleteRef.current) {
        autocompleteRef.current.addEventListener(
          'getaddress-autocomplete-address-selected',
          handleAddressSelected,
        );
      }
    }
  }, [inputRef, onSelect]);

  useEffect(() => {
    return () => {
      if (autocompleteRef.current) {
        autocompleteRef.current.removeEventListener(
          'getaddress-autocomplete-address-selected',
          handleAddressSelected,
        );
      }
    };
  }, []);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const handleClear = useCallback(() => {
    setText('');
    inputRef.current?.focus();
    onClear?.();
  }, [inputRef, onClear]);

  return (
    <Box position="relative" zIndex={1} width="100%">
      <SearchInput
        {...restInputProps}
        ref={inputRef}
        label={label}
        value={text}
        onChange={onChange}
        placeholder="Search address"
        onClear={isHideClearButton ? undefined : handleClear}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        isReadOnly={!!onClick}
        icon={icon}
      />
    </Box>
  );
};

export const GetGBAddressInput = memo(GetAddressInputComponent);
