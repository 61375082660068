import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import MarkerIcon from '@shared/assets/icons/marker.svg';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { Input } from '@shared/components/Input';
import { useIsMobile } from '@shared/utils/screen';
import { StorageKeys, storageSession } from '@shared/utils/storage';
import { validateEircode, validateFlatNo } from '@shared/utils/validation';
import { AddressModal } from 'components/AddressModal';
import get from 'lodash/get';
import React, { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectVenues } from 'store/slices/venues';

import { ConfirmFlatModal } from '../ConfirmFlatModal';
import { isDeliveryVenueSelectable } from '../helpers';
import { IselectVenue } from '../hooks';
import { VenuesModal } from '../VenuesModal';

interface Iprops {
  newAddress: Partial<Addresses.Address> | null;
  flatNo: string;
  eircode: string;
  setFlatNo: (value: string) => void;
  setEircode: (value: string) => void;
  setNewAddress: (values: Partial<Addresses.Address> | null) => void;
  flatNoError: string;
  setFlatNoError: (error: string) => void;
  eircodeError: string;
  setEircodeError: (error: string) => void;
  handleSelectDeliveryVenue: (args: IselectVenue) => void;
  setShowDeliveryDetailsSection: (isShown: boolean) => void;
  setShowServicesSection: (isShow: boolean) => void;
  handleSetStreetNumber: (value: string) => void;
  streetNumber: string;
}

const DeliveryDetailsSection = ({
  newAddress,
  flatNo,
  eircode,
  setFlatNo,
  setEircode,
  setNewAddress,
  flatNoError,
  setFlatNoError,
  eircodeError,
  setEircodeError,
  handleSelectDeliveryVenue,
  setShowDeliveryDetailsSection,
  setShowServicesSection,
  handleSetStreetNumber,
  streetNumber,
}: Iprops) => {
  const [isOpen, setOpen] = useState(false);
  const [isVenuesOpen, setVenuesOpen] = useState(false);
  const [isConfirmFlatOpen, setConfirmFlatOpen] = useState(false);
  const { isLoading, venues } = useAppSelector(selectVenues);
  const [isFlatNoRequired, setIsFlatNoRequired] = useState(
    !Boolean(streetNumber),
  );

  const venueIdSingle = storageSession.get(StorageKeys.VENUE_ID_SINGLE);

  const isMobile = useIsMobile();

  const handleSelect = useCallback(
    (values: Partial<Addresses.Address>) => {
      setFlatNoError('');
      setEircodeError('');

      setNewAddress(values);
      setFlatNo(values.flat_no || '');
      setEircode(values.eircode || '');
      handleSetStreetNumber(values.street_number || values.premise || '');
      setOpen(false);
      if (!values.eircode) {
        setEircodeError('Field is required');
      } else {
        setEircodeError('');
      }

      if (!values.id) {
        setFlatNoError(
          validateFlatNo(
            values.flat_no,
            !Boolean(values.street_number || values.premise),
          ),
        );
        setIsFlatNoRequired(!Boolean(values.street_number || values.premise));
      }
    },
    [setNewAddress, setEircode, setFlatNo, isFlatNoRequired],
  );

  const handleStartOrder = useCallback(() => {
    const selectVenue = (venue: Venues.Venue) => {
      const {
        id,
        name,
        services,
        location: { currency },
      } = venue;
      const serviceId: string = get(services, [0, 'id'], '');

      handleSelectDeliveryVenue({
        venueId: id,
        venueName: name,
        serviceId,
        currency,
      });
    };

    if (venueIdSingle) {
      /* available to order = is_online=true OR is_online=false && preorder=true */
      const currentVenue = venues.find(({ id }) => id === venueIdSingle);

      /* if venues list return venueId that is delivery possible true && available to order* -> open menu without opening the pop up */
      if (currentVenue && isDeliveryVenueSelectable(currentVenue, 0)) {
        selectVenue(currentVenue);
      } else {
        /* if venues list return >1 OR <1 venue that is delivery possible true && available to order* -> open pop up */
        setVenuesOpen(true);
      }
    } else {
      const availableVenues = venues.filter((venue) =>
        isDeliveryVenueSelectable(venue, 0),
      );
      /* if venues list return only 1 venue that is delivery possible true && available to order* -> open menu without opening the pop up */
      if (availableVenues.length === 1) {
        selectVenue(availableVenues[0]);
      } else {
        /* if venues list return >1 OR <1 venue that is delivery possible true && available to order* -> open pop up */
        setVenuesOpen(true);
      }
    }

    setVenuesOpen(true);
  }, [venues, handleSelectDeliveryVenue, venueIdSingle]);

  const handleStart = useCallback(() => {
    if (Boolean(flatNoError.length)) {
      setConfirmFlatOpen(true);
    } else {
      handleStartOrder();
    }
  }, [venues, handleSelectDeliveryVenue, handleStartOrder, flatNoError]);

  return (
    <Box>
      <Box marginBottom="16px">
        <BackButton
          onClick={() => {
            setShowDeliveryDetailsSection(false);
            setNewAddress(null);
            setShowServicesSection(true);
          }}
        />
      </Box>

      <Heading
        as="h1"
        fontSize={isMobile ? '24px' : '28px'}
        lineHeight={isMobile ? '28px' : '32px'}
        mb="24px"
      >
        Delivery details
      </Heading>

      <Box>
        <Flex
          marginBottom="16px"
          columnGap="16px"
          alignItems="center"
          padding="16px 0"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <Image src={MarkerIcon} alt="Location" />
          <Box flexGrow="1">
            <Text fontSize="14px" lineHeight="24px" fontWeight="600">
              {newAddress?.formatted_address}
            </Text>
          </Box>
          <Button
            variant="secondary"
            size={isMobile ? 'sm' : 'md'}
            onClick={() => setOpen(true)}
            paddingX="24px"
          >
            Change
          </Button>
        </Flex>

        <Flex columnGap="16px" marginBottom={isMobile ? '24px' : '80px'}>
          <FormControl>
            <FormLabel fontSize="12px" lineHeight="16px" fontWeight="600">
              House/Flat number
            </FormLabel>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFlatNoError(
                  validateFlatNo(e.target.value, isFlatNoRequired),
                );
                setFlatNo(e.target.value);
              }}
              value={flatNo}
              error={flatNoError}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="12px" lineHeight="16px" fontWeight="600">
              Post code
            </FormLabel>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;

                setEircodeError(validateEircode(value));

                setEircode(value);
              }}
              value={eircode}
              error={eircodeError}
            />
          </FormControl>
        </Flex>

        <Button
          size="full"
          isDisabled={isLoading || Boolean(eircodeError.length)}
          isLoading={isLoading}
          onClick={handleStart}
        >
          Start order
        </Button>

        {isOpen && (
          <AddressModal
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            onSelect={handleSelect}
            skipSecondScreen
          />
        )}

        {isVenuesOpen && (
          <VenuesModal
            isOpen={isVenuesOpen}
            onClose={() => setVenuesOpen(false)}
            address={newAddress?.formatted_address}
            handleClick={handleSelectDeliveryVenue}
          />
        )}

        {isConfirmFlatOpen && (
          <ConfirmFlatModal
            isOpen={isConfirmFlatOpen}
            onClose={() => setConfirmFlatOpen(false)}
            onConfirm={handleStartOrder}
          />
        )}
      </Box>
    </Box>
  );
};

export default DeliveryDetailsSection;
