import {
  Box,
  Button,
  Center,
  CircularProgress,
  Container,
  Divider,
  Flex,
  Image,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PhoneIcon from '@shared/assets/icons/call-2.svg';
import FileIcon from '@shared/assets/icons/file-text.svg';
import Schedule from '@shared/components/CheckoutViews/Schedule';
import { Input } from '@shared/components/Input';
import { PhoneInput } from '@shared/components/Input/PhoneInput';
import { LoadingBlock } from '@shared/components/LoadingBlock';
import { LabelWithPriceText } from '@shared/components/Texts/LabelWithPriceText';
import { ASAP_TIMESLOT, TOOLBAR_HEIGHT } from '@shared/constants/common';
import { PaymentProvider } from '@shared/constants/payment';
import { useElementResize } from '@shared/hooks/useElementResize';
import { formatCurrency, formatOnlyNumbers } from '@shared/utils/format';
import {
  getOrderExpireTime,
  getVivaCheckoutUrl,
} from '@shared/utils/getEnvData';
import { percentageOfNumber } from '@shared/utils/number';
import { parseStringToObject } from '@shared/utils/object';
import { generateOrderItems } from '@shared/utils/product';
import { useIsMobile } from '@shared/utils/screen';
import { storage, StorageKeys, storageSession } from '@shared/utils/storage';
import { validatePhoneNumber } from '@shared/utils/validation';
import TagManager from '@sooro-io/react-gtm-module';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { OrdersApi } from 'api/OrdersApi';
import { ServicesApi } from 'api/ServicesApi';
import { VenuesApi } from 'api/VenuesApi';
import { fontsForStripe } from 'assets/fonts';
import OrderDetailsList from 'components/OrderDetailsList';
import { WidgetWrapper } from 'components/WidgetWrapper';
import {
  WIDGET_PLACE_ORDER,
  WIDGET_PROMOTION_REDEEMED,
} from 'constants/googleTagManagerEvents';
import get from 'lodash.get';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  matchRoutes,
  unstable_useBlocker as useBlocker,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import roundHalfEven from 'round-half-even';
import { Paths } from 'routes/paths';
import { useAppDispatch } from 'store/hooks';
import {
  setIsCouponModalOpen,
  setSelectedCouponCode,
} from 'store/slices/checkoutCoupons';
import { clearUsedVoucherData } from 'store/slices/checkoutVouchers';
import { clearBasket } from 'store/slices/productModal';
import { getTables } from 'store/slices/tables';
import { setCountryCodes } from 'store/slices/uiServices';
import { isLandingMode } from 'utils/document';
import { getStripeApiKey } from 'utils/getEnvData';
import { prepareOrderItems } from 'utils/product';

import { DeliveryAddress } from './DeliveryAddress';
import { Discounts } from './Discounts';
import { useCheckout, usePayments } from './hooks';
import { PayByCardForm } from './PayByCardForm';
import { Tables } from './Tables';
import Tip from './Tip';

const stripePromise = loadStripe(getStripeApiKey());

const Checkout = () => {
  const {
    actions: {
      setDialCode,
      setLoading,
      setCheckoutData,
      setDeliverySurcharge,
      setDiscount,
      setAddress,
      setTable,
      setInputValues,
      setErrors,
      handleSelectTimeSlot,
      setTip,
      setCustomTip,
      setDriverTip,
      setCustomDriverTip,
      setTimeZone,
      handlePriceError,
      handleClearPriceErrors,
    },
    data: {
      blockedLoading,
      basketData,
      reorderData,
      serviceId,
      venueId,
      websiteData,
      usedVoucherData,
      user,
      serviceType,
      currency,
      isDelivery,
      isTable,
      dialCode,
      isLoading,
      checkoutData,
      deliverySurcharge,
      discount,
      address,
      table,
      inputValues,
      errors,
      isAsapAvailable,
      selectedTimeSlot,
      isAllowPreorder,
      hasTips,
      hasDriverTips,
      tip,
      customTip,
      driverTip,
      customDriverTip,
      productWithDepositCount,
      totalDeposit,
      timeZone,
      timeSlots,
      dayTimeSlots,
    },
  } = useCheckout();

  const {
    setVenue,
    setShowStripeCardForm,
    setPaymentMethods,
    showStripeCardForm,
    paymentMethods,
    showPayByCard,
    showPayByCardFormDirectly,
    showCheckoutText,
    isStripe,
    isViva,
  } = usePayments();

  const [search, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const isRequestedRef = useRef(false);

  const [leftCardRef, { fullHeight: rightCardMaxHeight }] =
    useElementResize<HTMLDivElement>();

  const isMobile = useIsMobile();

  const [isUsePoint, setIsUsePoint] = useState<boolean>(false);
  // @TODO maybe can be removed by [discount, setDiscount]
  const [discountData, setDiscountData] = useState<Discounts.Discount>();

  if (
    (basketData.length < 1 && reorderData.length < 1) ||
    !venueId ||
    !serviceId
  ) {
    navigate(
      !venueId || !serviceId
        ? isLandingMode
          ? Paths.Order
          : Paths.Home
        : generatePath(Paths.Menu, {
            venueId,
            serviceId,
            department: null,
          }),
      { replace: true },
    );
    return null;
  }

  const tipAmount = customTip.length
    ? +customTip
    : roundHalfEven(
        Number(
          percentageOfNumber(
            checkoutData?.subtotal_amount ? +checkoutData.subtotal_amount : 0,
            tip,
          ).toFixed(3),
        ),
      );

  const driverTipAmount = customDriverTip.length
    ? +customDriverTip
    : roundHalfEven(
        Number(
          percentageOfNumber(
            checkoutData?.subtotal_amount ? +checkoutData.subtotal_amount : 0,
            driverTip,
          ).toFixed(3),
        ),
      );

  useBlocker(({ nextLocation }) => {
    const match = matchRoutes(
      [{ path: Paths.Menu }, { path: Paths.Checkout }, { path: Paths.Profile }],
      nextLocation,
    );
    if (!match) {
      dispatch(clearBasket());
    }
    return false;
  });

  const syncCart = (orderId?: string) => {
    void OrdersApi.syncCart({
      order_id: orderId ?? null,
      venue: venueId,
      venue_group_id: websiteData?.venue_group.id || '',
      service: serviceId,
      website: websiteData?.id || '',
      products: [
        ...reorderData,
        ...generateOrderItems(basketData, prepareOrderItems),
      ],
    });
  };

  const createOrder = () => {
    setCheckoutData(undefined);
    const draftOrderId = storage.get(StorageKeys.DRAFT_ORDER_ID);

    syncCart();

    (draftOrderId
      ? OrdersApi.editOrderCheckout(draftOrderId, {
          venue: venueId,
          service: serviceId,
          order_items: [
            ...reorderData,
            ...generateOrderItems(basketData, prepareOrderItems),
          ],
        })
      : OrdersApi.checkout({
          venue: venueId,
          service: serviceId,
          order_items: [
            ...reorderData,
            ...generateOrderItems(basketData, prepareOrderItems),
          ],
          website: websiteData?.id || '',
        })
    )
      .then((checkoutRes) => {
        setCheckoutData(checkoutRes);

        handleClearPriceErrors();

        const lAddress = storage.get(StorageKeys.ADDRESS);
        if (isDelivery && lAddress) {
          const parsedAddress = lAddress
            ? parseStringToObject<Addresses.Address>(lAddress) ?? undefined
            : undefined;
          setAddress(parsedAddress);
        } else {
          if (inputValues.phone) {
            void OrdersApi.editOrder(checkoutRes.id, {
              phone_number: `${dialCode}${
                dialCode === '+353' && inputValues.phone.startsWith('0')
                  ? inputValues.phone.slice(1)
                  : inputValues.phone
              }`,
            });
          }
          setLoading(false);
        }
      })
      .catch((error: ApiTypes.ResponseError) => {
        if (draftOrderId) {
          storage.remove(StorageKeys.DRAFT_ORDER_ID);
          return createOrder();
        }
        isRequestedRef.current = true;
        toast({
          description: error.message || String(error),
          status: 'error',
          isClosable: true,
        });

        handlePriceError(error);
      })
      .finally(() => {
        storage.remove(StorageKeys.DRAFT_ORDER_ID);
        isRequestedRef.current = true;
      });
  };

  /**
   * Effect to listen for viva wallet status
   * success - redirect to OrderStatus
   * failure - show toast
   */
  useLayoutEffect(() => {
    const vivaStatus = search.get('viva');

    if (vivaStatus === 'failure') {
      toast({
        description: 'Something went wrong with your payment via VivaWallet',
        status: 'error',
        isClosable: true,
      });
      setSearchParams(undefined, { replace: true });
    }
  }, [search]);

  useEffect(() => {
    const vivaStatus = search.get('viva');
    if (vivaStatus === 'success') return;

    dispatch(getTables(venueId));

    VenuesApi.getVenue(venueId, 'config,location,services')
      .then((res) => {
        setVenue(res);
        // check and re-set service
        const service = res.services.find((s) => s.id === serviceId);
        if (service) {
          storageSession.set(
            StorageKeys.SERVICE_TYPE,
            String(service.service_type),
          );
          storageSession.set(
            StorageKeys.SERVICE_NAME,
            service.service_type === 1 ? 'Table' : service.name,
          );
          storage.set(StorageKeys.CURRENCY, res.location.currency);
        }
        // set time zone
        setTimeZone(res.location.timezone);
        // set country codes filter for autocomplete
        dispatch(setCountryCodes([res.location.country_code]));

        // set default dialCode
        if (!inputValues.phone && res?.location?.phone_country_code) {
          setDialCode(`+${res.location.phone_country_code}`);
        }
      })
      .catch((error: Error) => {
        toast({
          description: error.message || String(error),
          status: 'error',
          isClosable: true,
        });
      });

    createOrder();
    const expireTimeInMinutes = getOrderExpireTime();

    const interval = setInterval(
      createOrder,
      ((expireTimeInMinutes && Number(expireTimeInMinutes)) || 30) * 60_000,
    );

    return () => {
      clearInterval(interval);
    };
  }, [serviceType, websiteData]);

  useEffect(() => {
    if (websiteData) {
      VenuesApi.getPaymentMethods({
        venueId,
        serviceType: Number(serviceType),
        websiteId: websiteData.id,
      })
        .then((res) => {
          setPaymentMethods({
            cash: res.find((pm) => pm.is_active && pm.payment_method === 4),
            card: res.find((pm) => pm.is_active && pm.payment_method === 3),
          });
        })
        .catch((error: Error) => {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        });
    }
  }, [serviceType, websiteData]);

  useEffect(() => {
    //After first render - edit checkout if basket/table changed
    if (isRequestedRef.current) {
      editCheckout();
      syncCart();
    }
  }, [
    JSON.stringify(basketData),
    JSON.stringify(table),
    JSON.stringify(reorderData),
  ]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setInputValues((prevState) => ({
        ...prevState,
        [name]: name === 'phone' ? formatOnlyNumbers(value) : value,
      }));
    },
    [],
  );

  const onPhoneFocus = useCallback(() => {
    setShowStripeCardForm(false);
    setErrors((prevState) => ({ ...prevState, phone: '' }));
  }, []);

  const clearTableError = useCallback(() => {
    setErrors((prevState) => ({ ...prevState, table: '' }));
  }, []);

  useEffect(() => {
    if (isRequestedRef.current && ((isDelivery && address) || dialCode)) {
      setLoading(true);
      editOrder();
    }
  }, [JSON.stringify(address), dialCode]);

  const handleCancel = useCallback(() => {
    setShowStripeCardForm(false);
  }, []);

  const editCheckout = useCallback(() => {
    if (!checkoutData) {
      createOrder();
    } else {
      setLoading(true);
      OrdersApi.editOrderCheckout(checkoutData.id, {
        venue: venueId,
        service: serviceId,
        order_items: [
          ...reorderData,
          ...generateOrderItems(basketData, prepareOrderItems),
        ],
        user_comment: inputValues.note,
        table: isTable && table ? table.id : undefined,
      })
        .then((res) => {
          setCheckoutData(res);

          handleClearPriceErrors();
        })
        .catch((error: ApiTypes.ResponseError) => {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });

          handlePriceError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    checkoutData,
    inputValues.note,
    basketData,
    table,
    createOrder,
    reorderData,
  ]);

  const checkDeliverySurcharge = useCallback(
    (address: Addresses.Address) => {
      if (!address) return;
      VenuesApi.getDeliverySurcharge({
        venueId,
        lat: address.lat,
        lng: address.lng,
      })
        .then((res) => {
          setDeliverySurcharge(Number(res.extra_charge_amount));
        })
        .catch((error: ApiTypes.ResponseError) => {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [venueId],
  );

  /**
   * return {true} - pass
   */
  const checkRequiredFields = useCallback(() => {
    const e = {
      phone: validatePhoneNumber(inputValues.phone),
      address: !address && isDelivery ? 'Delivery address is required' : '',
      table: isTable && !table ? 'Please select table' : '',
      timeSlot:
        isAllowPreorder && !selectedTimeSlot ? 'Please select time slot' : '',
    };

    setErrors(e);

    const res = !e.phone && !e.address && !e.table && !e.timeSlot;

    return res;
  }, [
    isAllowPreorder,
    selectedTimeSlot,
    inputValues.phone,
    address,
    errors,
    table,
  ]);

  const checkMinimumOrderValue = () => {
    const priceWithoutDiscount =
      Number(checkoutData.total_amount) + deliverySurcharge;
    const minValue = checkoutData.service.config.minimum_order_value;
    if (+minValue <= 0) return true;

    if (priceWithoutDiscount < +minValue) {
      toast({
        description: `Order cannot be less than ${minValue}`,
        status: 'error',
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  /**
   * return {true} - pass
   */
  const checkAvailability = useCallback(
    (cb: VoidFunction, skipUnloading?: boolean) => () => {
      if (!checkRequiredFields() || !checkMinimumOrderValue()) return;
      setLoading(true);
      ServicesApi.getAvailability(serviceId)
        .then((res) => {
          if (res.is_available) {
            cb();
          } else {
            throw res;
          }
        })
        .catch(() => {
          setLoading(false);
          toast({
            description: 'Unfortunately this venue is currently closed',
            status: 'error',
            isClosable: true,
          });
        })
        .finally(() => {
          !skipUnloading && setLoading(false);
        });
    },
    [serviceId, checkRequiredFields, checkoutData],
  );

  const checkAndSaveTimeSlot = useCallback(
    (cb: VoidFunction, skipUnloading?: boolean) => () => {
      if (!checkoutData || !checkRequiredFields() || !checkMinimumOrderValue())
        return;
      setLoading(true);
      OrdersApi.editOrder(checkoutData.id, {
        complete_at:
          selectedTimeSlot === ASAP_TIMESLOT ? null : selectedTimeSlot ?? null,
      })
        .then(() => {
          cb();
        })
        .catch(() => {
          setLoading(false);
          setErrors((prevState) => ({
            ...prevState,
            timeSlot: 'Invalid preorder time',
          }));
        })
        .finally(() => {
          !skipUnloading && setLoading(false);
        });
    },
    [selectedTimeSlot, checkoutData, checkRequiredFields],
  );

  const editOrder = useCallback(() => {
    if (!checkoutData) return;

    setErrors({ phone: '', address: '', table: '', timeSlot: '' });
    checkRequiredFields();
    storage.set(
      StorageKeys.PHONE,
      `${dialCode}${
        dialCode === '+353' && inputValues.phone.startsWith('0')
          ? inputValues.phone.slice(1)
          : inputValues.phone
      }`,
    );
    storage.set(
      StorageKeys.ADDRESS,
      isDelivery && address ? JSON.stringify(address) : '',
    );
    OrdersApi.editOrder(checkoutData.id, {
      delivery_address: isDelivery ? address : undefined,
      phone_number: inputValues.phone
        ? `${dialCode}${
            dialCode === '+353' && inputValues.phone.startsWith('0')
              ? inputValues.phone.slice(1)
              : inputValues.phone
          }`
        : undefined,
    })
      .then(() => {
        if (isDelivery && address) {
          checkDeliverySurcharge(address);
        } else {
          setLoading(false);
        }
      })
      .catch((error: ApiTypes.ResponseError) => {
        if (error.data?.errors?.delivery_address) {
          setErrors((prevState) => ({
            ...prevState,
            address: error.data.errors.delivery_address[0],
          }));
        } else {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        }
        setLoading(false);
      });
  }, [
    checkoutData,
    inputValues.phone,
    address,
    dialCode,
    checkRequiredFields,
    isDelivery,
  ]);

  const handlePay = useCallback(
    (
      payment_provider: Payments.PaymentProvider = PaymentProvider.None,
      paymentMethod?: Payments.PaymentMethod,
      onSuccess?: (res: Payments.PayRequestResponse) => void,
    ) => {
      if (!checkoutData) return setLoading(false);
      const paid_amount_without_discount =
        +checkoutData.total_amount +
        totalDeposit +
        deliverySurcharge +
        tipAmount +
        driverTipAmount;

      const paid_amount =
        paid_amount_without_discount -
        discount.amount -
        Number(get(usedVoucherData, 'value', 0));

      OrdersApi.pay(checkoutData.id, {
        payment:
          paymentMethod?.id ??
          paymentMethods.cash?.id ??
          paymentMethods.card?.id ??
          'free',
        paid_amount: paid_amount.toFixed(2),
        currency,
        payment_method:
          paid_amount === 0 && discount.data
            ? 'id' in discount.data
              ? 'discount'
              : 'points'
            : paymentMethods.cash?.id ?? 'free',
        used_points: (discount.data as Points.AvailablePoints)
          ?.available_points,
        payment_provider,
        discount: (discount.data as Discounts.Discount)?.id,
        delivery_surcharge: deliverySurcharge.toFixed(2),
        voucher: usedVoucherData?.id,
        voucher_amount: usedVoucherData?.value,
        tips_amount: tipAmount.toString(),
        driver_tips_amount: driverTipAmount.toString(),
        deposit_return_scheme_amount: totalDeposit.toString(),
      })
        .then(
          onSuccess
            ? onSuccess
            : () => {
                if (
                  discount.data &&
                  get(discount, ['data', 'discount_type']) === 0
                ) {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: WIDGET_PROMOTION_REDEEMED,
                    },
                  });
                }
                TagManager.dataLayer({
                  dataLayer: {
                    event: WIDGET_PLACE_ORDER,
                    order_id: checkoutData.id,
                    order_value_before_discount:
                      paid_amount_without_discount.toFixed(2),
                    order_value_after_discount: (
                      paid_amount_without_discount - discount.amount
                    ).toFixed(2), // don't include voucher
                    order_currency: currency,
                  },
                });

                syncCart(checkoutData.id);
                dispatch(clearUsedVoucherData());
                dispatch(clearBasket());
                navigate(
                  generatePath(Paths.OrderStatus, {
                    orderId: checkoutData.id,
                  }),
                );
              },
        )
        .catch((error: Error) => {
          setLoading(false);
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        });
    },
    [
      checkoutData,
      paymentMethods,
      discount,
      inputValues,
      address,
      deliverySurcharge,
      usedVoucherData,
      tipAmount,
      driverTipAmount,
      totalDeposit,
    ],
  );

  const onCouponClear = useCallback(() => {
    setDiscountData(undefined);
    setDiscount({ amount: 0 });
    dispatch(setSelectedCouponCode(''));
    dispatch(setIsCouponModalOpen(false));
    storage.remove(StorageKeys.COUPON);
    storage.remove(StorageKeys.COUPON_DISCOUNT_CODE);
    storage.remove(StorageKeys.DISCOUNT_AMOUNT);
    storage.remove(StorageKeys.DISCOUNT_DATA);
  }, []);

  const handleClearUsedVoucherData = () => {
    dispatch(clearUsedVoucherData());
    storage.remove(StorageKeys.VOUCHER_ID);
    storage.remove(StorageKeys.VOUCHER_VALUE);
    storage.remove(StorageKeys.VOUCHER_AVAILABLE_BALANCE);
  };

  const handlePayByCard = useCallback(() => {
    if (isStripe) {
      setShowStripeCardForm(true);
    }
    if (isViva) {
      handlePay(PaymentProvider.Viva, paymentMethods.card, (res) => {
        storage.set(StorageKeys.DRAFT_ORDER_ID, checkoutData.id);

        const vivaUrl = `${getVivaCheckoutUrl()}?ref=${res.id}&color=${
          websiteData?.primary_color
        }`;
        location.href = vivaUrl;
      });
    }
  }, [isStripe, isViva, handlePay, checkoutData, websiteData]);

  if (blockedLoading)
    return (
      <Center>
        <CircularProgress isIndeterminate color="primary.400" />
      </Center>
    );

  const priceWithoutDiscount =
    Number(checkoutData.total_amount) +
    deliverySurcharge +
    tipAmount +
    driverTipAmount;

  // don't include voucher as discount for google analytics
  const priceWithDiscountGA =
    priceWithoutDiscount + totalDeposit - discount.amount;

  const priceWithoutDiscountString = priceWithoutDiscount.toFixed(2);

  const totalPrice =
    priceWithoutDiscount +
    totalDeposit -
    discount.amount -
    Number(get(usedVoucherData, 'value', 0));

  const originalPrice = discount.amount
    ? +(priceWithoutDiscount + totalDeposit).toFixed(2)
    : undefined;

  // if order value - discount (voucher) === 0 -> allow to complete order without any payment
  const isCompleteOrderAvailable =
    Number((priceWithoutDiscount + totalDeposit).toFixed(2)) -
      discount.amount ===
      0 ||
    Number((priceWithoutDiscount + totalDeposit).toFixed(2)) -
      Number(get(usedVoucherData, 'value', 0)) ===
      0;

  return (
    <WidgetWrapper toolbar={{ title: 'Checkout', withBack: true }}>
      <LoadingBlock isOpen={isLoading} />
      <Container maxW="container.xl" px={isMobile ? 0 : '44px'}>
        <Stack
          direction="row"
          spacing="16px"
          justify="center"
          align="start"
          paddingY={isMobile ? 0 : '48px'}
        >
          <Box
            ref={leftCardRef}
            w={isMobile ? '100%' : '560px'}
            bgColor="white"
            borderRadius={isMobile ? 0 : '2xl'}
            py="24px"
            px={isMobile ? '16px' : '24px'}
            minH={
              isMobile
                ? `calc(100dvh - ${MENU_HEIGHT}px - ${TOOLBAR_HEIGHT}px)`
                : undefined
            }
          >
            <Stack direction="row" spacing="16px" py="16px">
              <Flex w="24px" h="40px" align="center">
                <Image src={PhoneIcon} w="18px" />
              </Flex>
              <PhoneInput
                name="phone"
                value={inputValues.phone}
                onChange={onInputChange}
                onFocus={onPhoneFocus}
                onBlur={editOrder}
                error={errors.phone}
                formControlProps={{
                  w: isMobile ? 'full' : '210px',
                }}
                size="md"
                dialCode={dialCode}
                setDialCode={setDialCode}
              />
            </Stack>
            <Divider />
            {isTable && (
              <>
                <Tables
                  table={table}
                  setTable={setTable}
                  error={errors.table}
                  clearError={clearTableError}
                />
                <Divider />
              </>
            )}
            {isDelivery && (
              <>
                <DeliveryAddress
                  address={address}
                  setAddress={setAddress}
                  error={errors.address}
                />
                <Divider />
              </>
            )}

            {isAllowPreorder && (
              <>
                <Schedule
                  selectedTimeSlot={selectedTimeSlot}
                  handleSelectTimeSlot={handleSelectTimeSlot}
                  error={errors.timeSlot}
                  isAsapAvailable={isAsapAvailable}
                  timeSlots={timeSlots}
                  dayTimeSlots={dayTimeSlots}
                  timeZone={timeZone}
                />
                <Divider />
              </>
            )}

            <Stack direction="row" align="center" spacing="16px" py="16px">
              <Box w="24px">
                <Image src={FileIcon} w="16px" />
              </Box>
              <Input
                placeholder={
                  isDelivery
                    ? 'Delivery instructions (e.g. meet at door)'
                    : 'Note (e.g. meet at door)'
                }
                name="note"
                value={inputValues.note}
                onChange={onInputChange}
                onBlur={editCheckout}
                size="md"
                maxLength={100}
              />
            </Stack>

            {!isUsePoint &&
              !Boolean(discountData) &&
              !usedVoucherData &&
              hasTips && (
                <Box marginBottom="16px">
                  <Tip
                    title="Add a tip?"
                    tip={tip}
                    customTip={customTip}
                    handleSelectTip={setTip}
                    handleSelectCustomTip={setCustomTip}
                    currency={currency}
                  />
                </Box>
              )}

            {!isUsePoint &&
              !Boolean(discountData) &&
              !usedVoucherData &&
              hasDriverTips && (
                <Box marginBottom="16px">
                  <Tip
                    title="Tip the driver?"
                    tip={driverTip}
                    customTip={customDriverTip}
                    handleSelectTip={setDriverTip}
                    handleSelectCustomTip={setCustomDriverTip}
                    currency={currency}
                  />
                </Box>
              )}

            <Stack spacing="8px" mt="24px">
              {Boolean(+checkoutData.subtotal_amount) && (
                <LabelWithPriceText
                  label="Subtotal"
                  price={checkoutData.subtotal_amount}
                />
              )}

              {totalDeposit && (
                <LabelWithPriceText
                  label="Deposit return scheme"
                  price={totalDeposit.toFixed(2)}
                  count={productWithDepositCount}
                />
              )}

              {Boolean(
                +checkoutData.service.config.delivery_fee_amount +
                  deliverySurcharge,
              ) && (
                <LabelWithPriceText
                  label="Delivery"
                  price={(
                    +checkoutData.service.config.delivery_fee_amount +
                    deliverySurcharge
                  ).toFixed(2)}
                />
              )}
              {Boolean(+checkoutData.service.config.service_fee_amount) && (
                <LabelWithPriceText
                  label="Processing Fee"
                  price={checkoutData.service.config.service_fee_amount}
                />
              )}
              {tipAmount && (
                <LabelWithPriceText
                  label="Tip"
                  price={tipAmount.toString()}
                  handleDelete={() => {
                    setTip(0);
                    setCustomTip('');
                  }}
                />
              )}
              {driverTipAmount && (
                <LabelWithPriceText
                  label="Driver tip"
                  price={driverTipAmount.toString()}
                  handleDelete={() => {
                    setDriverTip(0);
                    setCustomDriverTip('');
                  }}
                />
              )}
              {usedVoucherData && (
                <LabelWithPriceText
                  label="Voucher used"
                  price={usedVoucherData.value}
                  negative
                  handleDelete={handleClearUsedVoucherData}
                />
              )}
              {discountData && (
                <LabelWithPriceText
                  label="Discount"
                  price={discount.amount.toFixed(2)}
                  negative
                  handleDelete={onCouponClear}
                />
              )}
            </Stack>

            {!tipAmount && !driverTipAmount && (
              <Discounts
                orderId={checkoutData.id}
                subtotalAmount={checkoutData.subtotal_amount}
                totalAmount={priceWithoutDiscountString}
                onDiscount={setDiscount}
                isUsePoint={isUsePoint}
                setIsUsePoint={setIsUsePoint}
                discountData={discountData}
                setDiscountData={setDiscountData}
                totalDeposit={totalDeposit}
                onCouponClear={onCouponClear}
                totalPrice={totalPrice.toFixed(2)}
              />
            )}

            {!isLoading && (
              <LabelWithPriceText
                largeSize
                label="Your total"
                price={totalPrice.toFixed(2)}
                originalPrice={originalPrice}
                mb="40px"
              />
            )}

            {isCompleteOrderAvailable ? (
              <Button
                w="full"
                size="xl"
                onClick={
                  isAllowPreorder
                    ? checkAndSaveTimeSlot(handlePay)
                    : checkAvailability(handlePay)
                }
              >
                Complete order
              </Button>
            ) : paymentMethods.card &&
              (showStripeCardForm || showPayByCardFormDirectly) ? (
              <Elements
                stripe={stripePromise}
                options={{ fonts: fontsForStripe }}
              >
                <PayByCardForm
                  priceWithoutDiscount={priceWithoutDiscount}
                  priceWithDiscountGA={priceWithDiscountGA}
                  totalPrice={totalPrice}
                  onCancel={handleCancel}
                  name={user?.name as string}
                  email={user?.email as string}
                  phone={`${dialCode}${inputValues.phone}`}
                  orderId={checkoutData.id}
                  discount={discount}
                  deliverySurcharge={deliverySurcharge}
                  payment={paymentMethods.card}
                  validate={checkRequiredFields}
                  currency={currency}
                  checkAvailability={
                    isAllowPreorder ? checkAndSaveTimeSlot : checkAvailability
                  }
                  showPayByCardFormDirectly={Boolean(showPayByCardFormDirectly)}
                  tipAmount={tipAmount}
                  driverTipAmount={driverTipAmount}
                  syncCart={syncCart}
                  totalDeposit={totalDeposit}
                />
              </Elements>
            ) : (
              <Stack direction={isMobile ? 'column' : 'row'} spacing="16px">
                {showPayByCard && !showPayByCardFormDirectly && (
                  <Button
                    w="full"
                    size="xl"
                    onClick={
                      isAllowPreorder
                        ? checkAndSaveTimeSlot(handlePayByCard, isViva)
                        : checkAvailability(handlePayByCard, isViva)
                    }
                    isDisabled={Boolean(
                      errors.phone || errors.address || errors.timeSlot,
                    )}
                  >
                    {showCheckoutText ? 'Checkout' : 'Pay by card'}
                  </Button>
                )}
                {paymentMethods.cash && (
                  <Button
                    w="full"
                    size="xl"
                    variant="secondary"
                    onClick={
                      isAllowPreorder
                        ? checkAndSaveTimeSlot(handlePay)
                        : checkAvailability(handlePay)
                    }
                    isDisabled={Boolean(
                      errors.phone || errors.address || errors.timeSlot,
                    )}
                  >
                    Pay by cash
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          {!isMobile && (
            <Box
              maxH={rightCardMaxHeight}
              w="368px"
              bgColor="white"
              borderRadius="2xl"
              px="24px"
              py="36px"
              overflowY="auto"
            >
              <OrderDetailsList
                items={[...reorderData, ...basketData]}
                formattedCurrency={formatCurrency()}
              />
            </Box>
          )}
        </Stack>
      </Container>
    </WidgetWrapper>
  );
};

export default Checkout;
