import { Box, Flex, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { Checkbox } from '@shared/components/Checkbox';
import { PriceText } from '@shared/components/Texts/PriceText';
import { useIsMobile } from '@shared/utils/screen';
import { storage, StorageKeys } from '@shared/utils/storage';
import { OrdersApi } from 'api/OrdersApi';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setIsCouponModalOpen,
  setLoading,
  setSelectedCouponCode,
} from 'store/slices/checkoutCoupons';
import {
  selectCheckoutVouchers,
  setUsedVoucherData,
} from 'store/slices/checkoutVouchers';
import { selectWebsite } from 'store/slices/website';

import { Coupon } from './Coupon';
import { countCouponDiscount } from './helpers';
import Voucher from './Voucher';

interface Props {
  orderId: string;
  totalAmount: string;
  subtotalAmount: string;
  onDiscount: (discount: {
    amount: number;
    data?: Points.AvailablePoints | Discounts.Discount;
  }) => void;
  isUsePoint: boolean;
  setIsUsePoint: (use: boolean) => void;
  discountData?: Discounts.Discount;
  setDiscountData: (discountData?: Discounts.Discount) => void;
  totalDeposit: number;
  onCouponClear: () => void;
  totalPrice: string;
}

export const Discounts = ({
  orderId,
  totalAmount,
  subtotalAmount,
  onDiscount,
  isUsePoint,
  setIsUsePoint,
  discountData,
  setDiscountData,
  totalDeposit,
  onCouponClear,
  totalPrice,
}: Props) => {
  const dispatch = useAppDispatch();
  const { usedVoucherData } = useAppSelector(selectCheckoutVouchers);
  const { websiteData } = useAppSelector(selectWebsite);

  const isMobile = useIsMobile();
  const [pointsData, setPointsData] = useState<Points.AvailablePoints>();

  const toast = useToast();

  const totalAmountWithDeposit = (Number(totalAmount) + totalDeposit).toFixed(
    2,
  );

  const savedDiscountAmount = storage.get(StorageKeys.DISCOUNT_AMOUNT);
  const savedDiscountData = storage.get(StorageKeys.DISCOUNT_DATA);
  const savedCoupon = storage.get(StorageKeys.COUPON);
  const savedDiscountCoupon = storage.get(StorageKeys.COUPON_DISCOUNT_CODE);

  const savedVoucher = storage.get(StorageKeys.VOUCHER_ID);
  const savedVoucherValue = storage.get(StorageKeys.VOUCHER_VALUE);
  const savedVoucherBalance = storage.get(
    StorageKeys.VOUCHER_AVAILABLE_BALANCE,
  );

  useEffect(() => {
    const request = async () => {
      const res = await OrdersApi.availablePoints(
        totalAmountWithDeposit,
        orderId,
      );
      setPointsData(res);
    };
    void request();
  }, [totalAmountWithDeposit]);

  const onChange = useCallback(
    (value: string[]) => {
      const checked = value[0] === 'point';
      if ((checked && !!discountData) || !!usedVoucherData) {
        toast.closeAll();
        toast({
          description: `You can not use ${
            websiteData?.venue_group?.points_config?.name || 'points'
          } with coupon code applied`,
          position: 'bottom-left',
          isClosable: true,
          status: 'info',
        });
        setIsUsePoint(false);
      } else {
        onDiscount({
          amount: checked ? Number(pointsData?.discount_amount) : 0,
          data: checked ? pointsData : undefined,
        });
        setIsUsePoint(checked);

        if (checked) {
          storage.set(
            StorageKeys.DISCOUNT_AMOUNT,
            JSON.stringify(pointsData?.discount_amount),
          );
          storage.set(StorageKeys.DISCOUNT_DATA, JSON.stringify(pointsData));
          storage.remove(StorageKeys.COUPON);
          storage.remove(StorageKeys.COUPON_DISCOUNT_CODE);

          storage.remove(StorageKeys.VOUCHER_ID);
          storage.remove(StorageKeys.VOUCHER_VALUE);
          storage.remove(StorageKeys.VOUCHER_AVAILABLE_BALANCE);
        } else {
          storage.remove(StorageKeys.DISCOUNT_AMOUNT);
          storage.remove(StorageKeys.DISCOUNT_DATA);
        }
      }
    },
    [
      discountData,
      onDiscount,
      pointsData,
      usedVoucherData,
      websiteData?.venue_group?.points_config?.name,
    ],
  );

  useEffect(() => {
    //set points from the local storage
    if (
      savedDiscountAmount &&
      savedDiscountData &&
      pointsData &&
      !discountData &&
      !usedVoucherData
    ) {
      onDiscount({
        amount: Number(pointsData?.discount_amount),
        data: pointsData,
      });
      setIsUsePoint(true);
    }
  }, [
    savedDiscountAmount,
    savedDiscountData,
    onChange,
    discountData,
    usedVoucherData,
  ]);

  const onCouponApply = useCallback(
    (coupon: string, isDiscountCode?: boolean) => {
      if (isUsePoint) {
        toast.closeAll();
        toast({
          description: `You can not apply coupon code while using ${
            websiteData?.venue_group?.points_config?.name || 'points'
          }`,
          position: 'bottom-left',
          isClosable: true,
          status: 'info',
        });
      } else if (Boolean(usedVoucherData)) {
        toast.closeAll();
        toast({
          description: `You can not use ${
            websiteData?.venue_group?.points_config?.name || 'points'
          } with voucher applied`,
          position: 'bottom-left',
          isClosable: true,
          status: 'info',
        });
      } else {
        if (isDiscountCode) {
          storage.set(StorageKeys.COUPON_DISCOUNT_CODE, coupon);
          storage.remove(StorageKeys.COUPON);
        } else {
          storage.set(StorageKeys.COUPON, coupon);
          storage.remove(StorageKeys.COUPON_DISCOUNT_CODE);
        }

        storage.remove(StorageKeys.DISCOUNT_AMOUNT);
        storage.remove(StorageKeys.DISCOUNT_DATA);
        storage.remove(StorageKeys.VOUCHER_ID);
        storage.remove(StorageKeys.VOUCHER_VALUE);
        storage.remove(StorageKeys.VOUCHER_AVAILABLE_BALANCE);
        dispatch(setLoading(true));

        OrdersApi.discount(coupon, orderId)
          .then((res) => {
            setDiscountData(res);

            // if res.includes_adjustments is false, then discount should be applied only on subtotal amount
            const amountToDiscount = !res.includes_adjustments
              ? subtotalAmount
              : totalAmountWithDeposit;

            onDiscount({
              amount: countCouponDiscount(
                res.discount_type,
                Number(amountToDiscount),
                Number(res.discount_value),
              ),
              data: res,
            });
            dispatch(setIsCouponModalOpen(false));
          })
          .catch((error: Error) => {
            setDiscountData(undefined);
            dispatch(setSelectedCouponCode(''));

            storage.remove(StorageKeys.COUPON);
            storage.remove(StorageKeys.COUPON_DISCOUNT_CODE);

            toast({
              description: error.message || String(error),
              status: 'error',
              isClosable: true,
            });
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    },
    [
      isUsePoint,
      onDiscount,
      totalAmountWithDeposit,
      usedVoucherData,
      websiteData?.venue_group?.points_config?.name,
    ],
  );

  useEffect(() => {
    const code = savedCoupon || savedDiscountCoupon;
    // set coupon or discount_code from the local storage
    // need to be recalculated on the totalAmount change

    if (code) {
      onCouponApply(code, Boolean(savedDiscountCoupon));
      dispatch(setSelectedCouponCode(code));
    }
  }, [savedCoupon, savedDiscountCoupon, totalAmount]);

  useEffect(() => {
    // set voucher from the local storage
    if (savedVoucher && savedVoucherValue && savedVoucherBalance) {
      if (Number(totalPrice) >= 0) {
        dispatch(
          setUsedVoucherData({
            id: savedVoucher,
            value: savedVoucherValue,
            available_balance: savedVoucherBalance,
          }),
        );
      } else {
        // don't allow negative totalPrice, remove voucher data
        storage.remove(StorageKeys.VOUCHER_ID);
        storage.remove(StorageKeys.VOUCHER_VALUE);
        storage.remove(StorageKeys.VOUCHER_AVAILABLE_BALANCE);
        dispatch(setUsedVoucherData(null));
      }
    }
  }, [savedVoucher, savedVoucherValue, savedVoucherBalance, totalPrice]);

  return (
    <Box paddingTop="16px" paddingBottom="16px">
      {pointsData && Number(pointsData.available_points) > 0 && (
        <Flex
          w="100%"
          justify="space-between"
          align="center"
          marginBottom="24px"
        >
          <Checkbox
            value={isUsePoint ? ['point'] : []}
            onChange={onChange}
            position="start"
          >
            {[
              {
                value: 'point',
                element: (
                  <HStack spacing="8px">
                    <Text fontSize="xs" fontWeight={600}>
                      Use my{' '}
                      {websiteData?.venue_group?.points_config?.name ||
                        'points'}
                    </Text>
                    <Text
                      color="gray.400"
                      fontSize="xs"
                    >{`(You can use ${Number(pointsData.available_points)} ${
                      websiteData?.venue_group?.points_config?.name || 'points'
                    })`}</Text>
                  </HStack>
                ),
              },
            ]}
          </Checkbox>
          {isUsePoint && (
            <PriceText
              price={Number(pointsData.discount_amount)}
              color="gray.400"
              fontSize="xs"
              fontWeight={400}
              negative
            />
          )}
        </Flex>
      )}
      <Stack direction={isMobile ? 'column' : 'row'} spacing="16px">
        <Box width={isMobile || !websiteData?.show_voucher ? '100%' : '50%'}>
          <Flex
            direction={isMobile ? 'column' : 'row'}
            w="100%"
            justify="space-between"
            align="center"
          >
            <Coupon
              isApplied={Boolean(discountData)}
              appliedCode={discountData?.code}
              onCouponApply={onCouponApply}
              onCouponClear={onCouponClear}
              isVoucherUsed={Boolean(usedVoucherData)}
              isPointsUsed={isUsePoint}
            />
          </Flex>
        </Box>

        {websiteData?.show_voucher && (
          <Box width={isMobile ? '100%' : '50%'}>
            <Voucher
              isPointsUsed={isUsePoint}
              hasDiscount={Boolean(discountData)}
              totalAmount={totalAmountWithDeposit}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
