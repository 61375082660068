import {
  Box,
  Center,
  CircularProgress,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import MarkerIcon from '@shared/assets/icons/marker.svg';
import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';
import { StorageKeys, storageSession } from '@shared/utils/storage';
import { useAppSelector } from 'store/hooks';
import { selectUiServices } from 'store/slices/uiServices';
import { selectVenues } from 'store/slices/venues';

import { IselectVenue } from '../hooks';
import VenueItem from './VenueItem';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  address?: string;
  handleClick: (args: IselectVenue) => void;
}

export const VenuesModal = ({
  isOpen,
  onClose,
  address,
  handleClick,
}: Props) => {
  const isMobile = useIsMobile();

  const { selectedServiceType } = useAppSelector(selectUiServices);

  const { isLoading, venues } = useAppSelector(selectVenues);
  const venueIdSingle = storageSession.get(StorageKeys.VENUE_ID_SINGLE);

  let filteredVenues: Venues.Venue[] = [];

  // don't check is_online, we will show it as closed
  if (selectedServiceType !== null) {
    if (selectedServiceType === 0) {
      filteredVenues = venues.filter(
        ({ is_delivery_possible }) => is_delivery_possible === true,
      );
    } else {
      // null === default true
      filteredVenues = venues.filter(
        ({ is_delivery_possible }) =>
          is_delivery_possible === true || is_delivery_possible === null,
      );
    }
  }

  let errorMessage = 'Unfortunately no venues service your area';
  let showSingleVenueError = false;

  if (selectedServiceType === 0 && venueIdSingle) {
    showSingleVenueError = true;
    if (filteredVenues.length) {
      errorMessage =
        "Unfortunately, this venue doesn't deliver to your address. Please select another one or change delivery address.";
    } else {
      errorMessage =
        "Unfortunately, this venue doesn't deliver to your address. Please select a different delivery address.";
    }
  } else if (selectedServiceType !== 0 && venueIdSingle) {
    showSingleVenueError = true;
    if (filteredVenues.length) {
      errorMessage =
        'Unfortunately, the selected venue is not available for the chosen service. You may choose a different venue or adjust your service selection.';
    } else {
      errorMessage = 'Unfortunately, the chosen service is not available.';
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      size={{ mobile: 'full', desktop: '3xl' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
    >
      <Heading size="sm" lineHeight="32px" mb="24px">
        Choose venue
      </Heading>

      {address && (
        <Flex columnGap="20px" alignItems="flex-start" marginBottom="24px">
          <Image src={MarkerIcon} alt="Location" />
          <Box>
            <Text
              fontSize="12px"
              lineHeight="16px"
              marginBottom="4px"
              color="gray.400"
            >
              Delivery address
            </Text>
            <Text fontSize="14px" lineHeight="24px" fontWeight="600">
              {address}
            </Text>
          </Box>
        </Flex>
      )}

      {venueIdSingle && showSingleVenueError && (
        <Text
          size="sm"
          lineHeight="32px"
          textAlign="center"
          color="gray.400"
          width="100%"
        >
          {errorMessage}
        </Text>
      )}

      {isLoading && (
        <Center>
          <CircularProgress isIndeterminate color="primary.400" />
        </Center>
      )}
      {!isLoading && (
        <Flex rowGap={'8px'} flexWrap="wrap">
          {Boolean(filteredVenues.length) && (
            <>
              {filteredVenues.map((venue) => (
                <VenueItem
                  key={venue.id}
                  venue={venue}
                  handleClick={handleClick}
                />
              ))}
            </>
          )}
          {!Boolean(filteredVenues.length) && !venueIdSingle && (
            <Text
              size="sm"
              lineHeight="32px"
              textAlign="center"
              color="gray.400"
              width="100%"
            >
              {errorMessage}
            </Text>
          )}
        </Flex>
      )}
    </Modal>
  );
};
