export const isVenueSelectable = (
  venue: Venues.Venue,
  serviceType: Services.ServiceType,
): boolean => {
  const isAllowPreorder = venue.services.find(
    (service) => service.service_type === serviceType,
  )?.config.allow_preorder;

  if (venue.is_delivery_possible !== false && venue.is_online === true) {
    return true;
  } else if (venue.is_delivery_possible !== false && isAllowPreorder) {
    return true;
  }

  return false;
};

// only for delivery service type
export const isDeliveryVenueSelectable = (
  venue: Venues.Venue,
  serviceType: Services.ServiceType,
): boolean => {
  const isAllowPreorder = venue.services.find(
    (service) => service.service_type === serviceType,
  )?.config.allow_preorder;

  if (venue.is_delivery_possible === true && venue.is_online === true) {
    return true;
  } else if (venue.is_delivery_possible === true && isAllowPreorder) {
    return true;
  }
  return false;
};
