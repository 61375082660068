import { Box, Button } from '@chakra-ui/react';
import { StorageKeys, storageSession } from '@shared/utils/storage';
import get from 'lodash/get';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUiServices } from 'store/slices/uiServices';
import { selectVenues } from 'store/slices/venues';

import { isVenueSelectable } from '../helpers';
import { IselectVenue } from '../hooks';
import { VenuesModal } from '../VenuesModal';

interface Iprops {
  handleSelectOtherServiceVenue: (args: IselectVenue) => void;
}

const OtherServiceSection = ({ handleSelectOtherServiceVenue }: Iprops) => {
  const [isVenuesOpen, setVenuesOpen] = useState(false);
  const { isLoading, venues } = useAppSelector(selectVenues);

  const { selectedServiceType } = useAppSelector(selectUiServices);

  const venueIdSingle = storageSession.get(StorageKeys.VENUE_ID_SINGLE);

  const handleStartOrder = useCallback(() => {
    const selectVenue = (venue: Venues.Venue) => {
      const {
        id,
        name,
        services,
        location: { currency },
      } = venue;
      const serviceId: string = get(services, [0, 'id'], '');

      handleSelectOtherServiceVenue({
        venueId: id,
        venueName: name,
        serviceId,
        currency,
      });
    };

    if (venueIdSingle) {
      const currentVenue = venues.find(({ id }) => id === venueIdSingle);
      if (
        currentVenue &&
        selectedServiceType !== null &&
        isVenueSelectable(currentVenue, selectedServiceType)
      ) {
        selectVenue(currentVenue);
        return;
      }
    } else if (venues.length === 1) {
      selectVenue(venues[0]);
      return;
    }

    setVenuesOpen(true);
  }, [
    venues,
    venueIdSingle,
    handleSelectOtherServiceVenue,
    selectedServiceType,
  ]);

  return (
    <Box>
      <Button
        size="full"
        isDisabled={isLoading}
        isLoading={isLoading}
        onClick={handleStartOrder}
      >
        Start order
      </Button>

      {isVenuesOpen && (
        <VenuesModal
          isOpen={isVenuesOpen}
          onClose={() => setVenuesOpen(false)}
          handleClick={handleSelectOtherServiceVenue}
        />
      )}
    </Box>
  );
};

export default OtherServiceSection;
